<template>
  <v-container style="margin-top: 30px">
    <v-row>
      <v-col cols="36" sm="6" md="4">
        <Card
          title="Students"
          ic="students"
          link=""
          :total="this.totalStudents"
        />
      </v-col>
      <v-col cols="36" sm="6" md="4">
        <Card
          title="Levels"
          ic="levels"
          link=""
          :total="this.totalProgrammes"
        />
      </v-col>
      <v-col cols="36" sm="6" md="4">
        <Card
          title="Messages"
          ic="messages"
          link=""
          :total="this.totalProgrammes"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LevelAdvisingAPI from "../../../apis/LevelAdvising";
import AcademicSessionAPI from "../../../apis/AcademicSession";
import UserAPI from "../../../apis/User";
import ProgrammeAPI from "../../../apis/Programme";
import Card from "../../Card.vue";
//import LevelAdviserMixin from "../../../mixins/LevelAdviserMixin";

export default {
  name: "AdminOverviewCards",
  //mixins: [LevelAdviserMixin],
  components: {
    Card,
  },
  data() {
    return {
      totalStudents: 0,
      totalProgrammes: 0,
      institution_id: null,
      department_id: null,
      currentUser: null,
      currentLevelAdvising: null,
      currentProgramme: null,
      currentLevel: null,
      currentAcademicSession: null,
    };
  },

  methods: {
    initialize() {
      UserAPI.auth().then((response) => {
        this.currentUser = response.data.data;
        this.institution_id = response.data.institution_id;
        this.department_id = this.levelAdvisingStore.department_id;
      });

      console.log(this.levelAdvisingStore.department_id);

      //------------------------------------------------------------------------------------------------------------
      AcademicSessionAPI.getCurrentAcademicSession()
        .then((response) => {
          if (response.data) {
            this.activeSessionExists = true;
          }
          this.currentAcademicSession = response.data;

          this.getAllLevelAdvisings(
            this.currentUser.id,
            this.currentAcademicSession.id
          );
        })
        .catch((error) => {
          console.log(error);
        });

      ProgrammeAPI.getProgrammeCount().then((response) => {
        this.totalProgrammes = response.data.length;
      });
    },

    getProgrameStudentsCountByLevel(department_id, level_id, programme_id) {
      let data = {
        department_id: department_id,
        level_id: level_id,
        programme_id: programme_id,
      };
      LevelAdvisingAPI.getProgrameStudentsCountByLevel(data).then(
        (response) => {
          this.totalStudents = response.data;
        }
      );
    },

    getAllLevelAdvisings(user_id, session_id) {
      let data = {
        user_id: user_id,
        session_id: session_id,
      };
      LevelAdvisingAPI.getAllLevelAdvisingss(data).then((response) => {
        this.currentLevelAdvising = response.data.data[0];
        this.getProgrameStudentsCountByLevel(
          this.department_id,
          this.currentLevelAdvising.level.id,
          this.currentLevelAdvising.programme.id
        );
      });
    },
  },
  created() {
    this.initialize();
  },
};
</script>

<style>
</style>
