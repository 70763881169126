import Api from './Api';
import Csrf from "./Csrf";


export default {
    async createTimeTable(newTimeTableForm) {
        await Csrf.getCookie();
        return Api.post('/timeTable/create', newTimeTableForm);
    },

    async getAllTimeTables(page, per_page) {
        await Csrf.getCookie();
        return Api.get('/timeTables/'+per_page+'?page=' + page);
    },

    async getTimeTableByFacultyAndSemesterId(faculty_id, semester_id) {
        await Csrf.getCookie();
        return Api.get('/timeTable/faculty/' + faculty_id + '/' + semester_id);
    },


    async updateTimeTable(id, updateData) {
        await Csrf.getCookie();
        return Api.patch('/timeTable/update/' + id, updateData);
    },
    async deleteTimeTable(id) {
        await Csrf.getCookie();
        return Api.patch('/timeTable/destroy/' + id);
    },

    async getTimeTableCount() {
        await Csrf.getCookie();
        return Api.get('/time-tables/count');
    },
};