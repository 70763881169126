import axios from 'axios';
import { useUserStore } from '../stores/userStore.js';
import Vue from 'vue';

import { createPinia, PiniaVuePlugin } from 'pinia';
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
const token = ''


pinia.use(async (context) => {
  if(context.store.$id == 'user'){
    token = context.store.auth_user.tertiary_token;
  }
})

// const token = useUserStore().auth_user.tertiary_token; 
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`  //Replace with your actual API token
let Api = axios.create({
   baseURL: window.location.protocol + '//' + window.location.hostname + '/api/',
});
// Set up a request interceptor to include the Bearer token in the header
Api.interceptors.request.use(
  (config) => {
    const token = useUserStore().auth_user.tertiary_token; // Replace with your actual API token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (response) => {
   alert('error');
      const login_endpoints = ['/sso/login', '/undergraduate/pre-admission/login', '/login'];
      // console.log('my response: ', response.config.url);
      if (login_endpoints.includes(response.config.url)){
        //  console.log('my response: ', response);
      }
   },

  (error) => {
    return Promise.reject(error);
  }
);
//Api.defaults.withCredentials = true;
export default Api;



// import axios from 'axios';


// let Api = axios.create({
//    // baseURL: process.env.MIX_API_URL
//    baseURL: window.location.protocol+'//'+window.location.hostname+'/api/'
// });

// Api.defaults.withCredentials = true;

// export default Api;