<template>
  <v-container class="card-container">
    <v-row class="justify-content-around">
      <v-col cols="12" sm="12" md="6">
        <Card title="Departments" ic="department" link="/faculty-exam-officer/departments" :total="this.totalDepartments"/>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <Card title="Staff" ic="staff" link="/faculty-exam-officer/staff" :total="this.totalStaff" />
      </v-col>
    </v-row>
    <v-row class="justify-content-around">
      <v-col cols="12" sm="12" md="6">
        <Card title="Students" ic="students" link="/faculty-exam-officer/students" :total="this.totalStudents" />
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <Card title="Programmes" ic="programmes" link="/faculty-exam-officer/programmes" :total="this.totalProgrammes" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import FacultyAPI from "../../../../apis/Faculty"
import UserAPI from "../../../../apis/User"
import Card from "../../../Card"

export default {
  name: "OverviewCards",
  components: {
    Card,
  },
  data() {
    return {
      totalStaff: 0,
      totalStudents: 0,
      totalProgrammes: 0,
      totalDepartments: 0,
  
    }
  },

  methods: {
    initialize() {
      UserAPI.auth().then(response => {

        FacultyAPI.getDeanDashboardCountsByFacultyID(response.data.data.faculty.id)
          .then(response => {
            this.totalDepartments = response.data.departmentCount;
            this.totalStaff = response.data.staffCount;
            this.totalStudents = response.data.studentCount;
            this.totalProgrammes = response.data.programmeCount;
            //   this.totalCourses = response.data.courseCount;
          });
      });
    },
  },
  mounted() {
    this.initialize();
  },
}
</script>

<style></style>
