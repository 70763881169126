import Api from './Api';
import Csrf from "./Csrf";


export default {
    async createAnnouncement(newAnnouncementForm) {
        await Csrf.getCookie();
        return Api.post('/announcement/create', newAnnouncementForm);
    },

    async getAnnouncements(programme_level_id, session_id, recipient, institute_id = null, page) {
        await Csrf.getCookie();
        return Api.get('/announcements/'+programme_level_id+ '/' + session_id+ '/' + recipient + '/' + institute_id + '?page='+page);
    },

    async updateAnnouncement(id, updateData) {
        await Csrf.getCookie();
        return Api.patch('/announcement/update/' + id, updateData);
    },

    async updateAnnouncementStatus(id, status) {
        await Csrf.getCookie();
        return Api.patch('/announcement/status-update/' + id + '/' + status);
    },

    async deleteAnnouncement(id) {
        await Csrf.getCookie();
        return Api.patch('/announcement/destroy/' + id);
    },
};