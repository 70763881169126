import { defineStore } from 'pinia';


export const useInstitutionStore = defineStore({
    id: 'institution',
    state: () => ({
        institution: {},
    }),
    actions: {
        fetchInstitutions() {
            // fetch institutions from API and set them to state
        },
        setInstitution(institution) {
            this.institution = institution;
        },
    },

    getters: {
        getInstitution(state) {
            return state.institution;
        },

        getDomain(state) {
            return state.institution.domain;
        },

        getUrl(state) {
            return state.institution.url;
        },

        getName(state) {
            return state.institution.name;
        },

        getAccronym(state) {
            return state.institution.accronym;
        },

        getCountry(state) {
            return state.institution.country;
        },
    },
});
