<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <Card
          title="Courses"
          ic="courses"
          link="/director/courses"
          :total="this.totalCourses"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserAPI from "../../../apis/User";
import Card from "../../Card";

export default {
  name: "AdminOverviewCards",
  components: {
    Card,
  },
  data() {
    return {
      totalCourses: 0,
      host: [],
    };
  },

  methods: {
    initialize() {
        UserAPI.getDirectorDashboardCountsByHostID(
          this.host.id, this.host.entity_type
        ).then((response) => {
          this.totalCourses = response.data.courseCount;
        });
    },
  },
  mounted() {
    const storedInstitute = sessionStorage.getItem('appointment');
    this.host = JSON.parse(storedInstitute);
    this.initialize();
  },
};
</script>

<style>
</style>
