<template>
    <v-menu offset-y v-model="open_menu">
        <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="mr-5 st-bg-icon">
                <v-icon>mdi-download-outline</v-icon>
            </v-btn>
        </template> -->
        <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip}">
                    <v-btn
                     v-bind="attrs" v-on="{...tooltip, ...menu}"
                     icon class="mr-5 st-bg-icon">
                        <v-icon>
                        mdi-download-outline
                        </v-icon>
                    </v-btn>
                </template>
                <span>Downloads</span>
            </v-tooltip>
        </template>


        <template v-if="downloads.length">
            <v-list lines="three" v-for="(download, index) in downloads"
                :key="index"
                max-height="60px"
            >
                <v-list-item>
                    <a v-if="download.status_name === 'Completed'" :href="download.url" :download="download.file_name">
                        <h6 style="font-size: 14px; font-weight: 400; color: #0000cc">{{ shortenFileName(download.file_name) }}</h6>
                        <p :style="{ fontSize: '14px', fontWeight: 700, color: getStatusColor(download.status_name) }">{{ download.status_name }}</p>
                    </a>
                    <div v-else>
                        <h6 style="font-size: 14px; font-weight: 400; color: #333">{{ shortenFileName(download.file_name) }}</h6>
                        <p :style="{ fontSize: '14px', fontWeight: 700, color: getStatusColor(download.status_name), display: 'block' }">{{ download.status_name }}</p>
                    </div>
                </v-list-item>
            </v-list>
        </template>
        <template v-else>
            <v-list>
                <v-list-item>
                    <v-list-item-title>
                        Download list empty
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </template>
    </v-menu>
</template>
<script>
import UserAPI from "../apis/User";
import { useUserStore } from '../stores/userStore'

export default {
    name: "FileDownloadList",
    data() {
        return {
            downloads: [],
            user: null,
            open_menu: false,
            token: useUserStore().auth_user.tertiary_token,
        }
    },
    created() {
        this.getUser()

    },
    methods: {
        async getDownloadList() {
            await axios.get(
                '/api/downloads/list',
                {
                    headers: {
                    Authorization: `Bearer ${this.token}`,
                },
                }
                ).then(response => {
                this.downloads = response.data.downloads
            })
        },
        getUser(){


            UserAPI.auth().then(async response => {
                const user = response.data.data
                await this.getDownloadList()

                Echo.private(`downloads.${user.user_id}`)
                    .listen('FileDownloadEvent', (e) => {
                        this.getDownload(e.download)
                    });

            })
        },
        getDownload(download) {
            let processed_download_index = this.downloads.findIndex((d) => {
                return d.id === download.id
            })
            if (processed_download_index !== -1) {
                this.downloads[processed_download_index].status = download.status
                this.downloads[processed_download_index].status_name = download.status_name
            } else {
                if (this.downloads.length >= 4) {
                    this.downloads.pop()
                }
                this.downloads.splice(0, 0, download)
                this.open_menu = true
            }
        },
        getStatusColor(statusName) {
            switch (statusName) {
                case "Processing":
                    return "orange";
                case "Completed":
                    return "green";
                case "Failed":
                    return "red";
                default:
                    return "";
            }
        },
        shortenFileName(file_name) {
            const max_length = 45;
            if (file_name.length <= max_length) {
                return file_name;
            }
            const file_name_without_extension = file_name.split('.').slice(0, -1).join('.');
            return file_name_without_extension.substring(0, max_length - 6) + '...' + file_name.split('.').pop();
        },
    },
}
</script>

<style scoped>

</style>
