<template>
  <div class="container mx-md-70 mx-3 my-4">
    <div>
      <v-list-item two-line v-if="designated_unit.length > 0">
        <v-list-item-content>
          <v-list-item-title class="headline">{{
            designated_unit.name
          }}</v-list-item-title>
          <v-list-item-title>{{
            designated_unit.faculty.name
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>

    <OverviewCards></OverviewCards>
  </div>
</template>

<script>
import OverviewCards from "./OverviewCards";
import UserAPI from "../../../apis/User";

export default {
  name: "CordinatorOverview",
  components: {
    OverviewCards,
  },
  data() {
    return {
      designated_unit: [],
    };
  },

  methods: {
    initialize() {
    },
  },

  mounted() {
    const stored_unit = sessionStorage.getItem('designated_unit');
    this.designated_unit = JSON.parse(stored_unit);
    this.initialize();
  },
};
</script>
