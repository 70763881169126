import { defineStore } from 'pinia';


export const useRoleStore = defineStore({
    id: 'role',
    state: () => ({
        role: {},
    }),
    actions: {
        setRole(role) {
            this.role = role;
        },

        roleId() {
            return this.role.id;
        }
    },

    getters: {
        getRole(state) {
            return state.role;
        },

        getName(state) {
            return state.role.name;
        },
    },
});
