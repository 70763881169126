<template>
  <v-app id="inspire">
    <v-main>
      <div class="nav-sidebar">
        <Navigation :authenticatedUser="userStore.auth">
          <template v-if="side_nav_items.length > 1" v-slot:items>
            <SiderBarItems :items="side_nav_items" :roles="userStore.getRoles" :permissions="userStore.getPermissions"
              usertype="student"
              environment="main"
            />
          </template>
        </Navigation>
      </div>
      <div class="main-content">
        <Overview v-if="$route.name === 'Student'" v-bind:authenticatedUser="userStore.auth"></Overview>
        <router-view :key="$route.fullPath" />
      </div>
    </v-main>
  </v-app>
</template>
<script>
import Navigation from "../../Navigation_modules/CustomNavigationDrawer";
import SiderBarItems from "../../Navigation_modules/SideBarItems";
import Overview from "./Overview.vue";
import StudentAPI from "../../../apis/Student";

export default {
  name: "StudentDashboard",
  components: { Navigation, Overview, SiderBarItems },

  data() {
    return {
      is_fresher: true,
      transfer_request_object: {
        text: 'Transfer Request',
        icon: 'transfer-requests',
        route: 'transfer-requests',
        permissions: []
      },
      clearance_object: { 
        text: "Clearance", 
        icon: "course-reg", 
        route: "clearance", 
        permissions: [] 
      },
      convocation_object: {
         text: "Convocation Fees", 
         icon: "payment-method", 
         route: "convocation-fees", 
         permissions: []
      },
      transcript_object: {
         text: "Academic Transcript", 
         icon: "degree", 
         route: "transcript", 
         permissions: []
      },
      // validation_object: {
      //   text: 'Transactions Validation',
      //   icon: 'transactions',
      //   route: 'transaction-validation',
      //   permissions: []
      // },

      side_nav_items: [],
      student_info: {},
    };
  },
  created() {
    // this.getStudent()
    // this.setSidenavMenu(this.userStore.getSubdomain);
    StudentAPI.getStudentByID(this.userStore.auth.id)
      .then(response => {
        this.student_info = response.data.student;
        this.is_fresher = response.data.student.is_fresher;
        let graduated = this.student_info.session_graduated;
        this.setSidenavMenu(this.userStore.getSubdomain);
        if(this.is_fresher == false) {
          this.side_nav_items.splice(5, 0, this.transfer_request_object)
        }
        if(graduated) {
          this.side_nav_items.splice(11, 0, this.clearance_object);
          this.side_nav_items.splice(12, 0, this.convocation_object);
          this.side_nav_items.splice(13, 0, this.transcript_object);
        }
    //     if(window.location.host.toLowerCase() !== 'portal.unilorin.edu.ng'){
    //      this.side_nav_items.splice(8, 0, this.validation_object);
    // }
      });
  },

  mounted() {

    // this.getStudent();
  },

  methods: {
    getStudent() {
    },
    setSidenavMenu(subdomain) {
      this.side_nav_items = [
        { text: "Home", icon: "dashboard", route: "", permissions: [] },
        { text: "Courses", icon: "courses", route: "courses", permissions: [] },
        // { text: "Inspired Home", icon: "ahea-sm", href:"https://" + subdomain +"." + process.env.MIX_INSPIRED_HOME + "?token=" + sessionStorage.getItem("access_token"), permissions: [],
        //   subItems: [
        //     { text: "Learning Management", icon: "", href: "https://" + subdomain + "." + process.env.MIX_INSPIRED_LM + "?token=" + sessionStorage.getItem("access_token"), permissions: [],},
        //     { text: "Collaboration Projects", icon: "", href: "https://" + subdomain + "." + process.env.MIX_INSPIRED_PROJECTS + "?token=" + sessionStorage.getItem("access_token"), permissions: [],},
        //     { text: "More Info", icon: "", route: "inspired", permissions: [] },
        //   ],
        // },
        //{ text: "LMS Home", icon: "lms", href:"lms/open", permissions: [] },
        { text: "Lectures", icon: "lecturer", route: "lectures", permissions: [] },
        // { text: "Assignments", icon: "assignments", route: "my-assignments", permissions: []},
        { text: "Announcements", icon: "annoucement", route: "announcements", permissions: [] },
        //{ text: 'CBT', icon: 'cbt', route: 'cbt', permission: ''},
        // { text: 'E-Library', icon: 'bookshelf', route: 'e-library', permissions: []},
        { text: "Course Management", icon: "course-management", route: "course", permissions: [] },
        { text: "Results", icon: "results", route: "my-results", permissions: [] },
        { text: 'Fees & Reciepts', icon: 'fees', route: 'fees', permissions: [] },
        { text: "Invoices", icon: "invoices", route: "invoices", permissions: [] },
        { text: "Supplementary Fees", icon: "payment-method", route: "supplementary-fee", permissions: [] },
        { text: 'Transactions Validation', icon: 'transactions', route: 'transaction-validation', permissions: [] },
        { text: "Hostel Accomodations", icon: "acccomodation", route: "hostel-booking", permissions: [] },
        { text: "Elections" , icon: "elections", route: "elections", permissions: [] },
      ];
    },
  },
};
</script>
