export class Errors {
    constructor() {
        this.errors = {};
    }

    has(field) {
        // eslint-disable-next-line no-prototype-builtins
        return this.errors.hasOwnProperty(field);
    }

    any() {
        return Object.keys(this.errors).length > 0;
    }

    record(errors) {
        this.errors = errors;
    }

    get(field) {
        if (this.errors[field]) {
            if (this.errors[field] == 'Invalid credentials') {
                return this.errors[field];
            } else {
                return this.errors[field][0];
            }
        }
    }

    clear(field) {
        delete this.errors[field];
    }

    clearAll() {
        this.errors = [];
    }
}