<template>
  <div>
    <v-card>
      <v-card-title>
        <h4>Level Adviser Overview</h4>
      </v-card-title>
      <v-card-text>
        <h4>{{ department}}</h4>
        <h5>{{level}}, {{ programme }}</h5>
      </v-card-text>
    </v-card>
    <!-- <OverviewCards></OverviewCards> -->
  </div>
</template>

<script>
import OverviewCards from "./LevelAdviserOverviewCards";

export default {
  name: "DepartmentOverview",

  components: {
    OverviewCards,
  },
  data() {
    return {
      dummyVar: [],
      department: null,
      level: null,
      programme: null,

      department_id: null,
      level_id: null,
    };
  },

  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.programme = this.levelAdvisingStore.programme_id;
      this.level = this.levelAdvisingStore.student_academic_level_id;
      this.department = this.levelAdvisingStore.department_name;
      this.level = this.levelAdvisingStore.level_name;
      this.programme = this.levelAdvisingStore.programme_name;
      this.department_id = this.levelAdvisingStore.department_id;
      this.level_id = this.levelAdvisingStore.student_academic_level_id;
      this.programme_id = this.levelAdvisingStore.programme_id;
    },
  },
};
</script>
