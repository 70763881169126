<template>
    <div>
      <v-breadcrumbs :items="nav_items" large>
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
    </div>
  </template>

<script>
export default {
  data: () => ({
  }),
  props: {
    nav_items:{type:Array}
    },
}
</script>