<template>
  <v-app id="inspire">
     <v-main>
      <Navigation 
        :authenticatedUser="userStore.auth"
      > 
        <template v-slot:items>
          <SiderBarItems 
            :items="side_nav_items" 
            :roles="userStore.getRoles"
            :permissions="userStore.getPermissions"
            usertype="staff"
            environment="main"
          />
        </template>
      </Navigation>
      <template>
          <Overview  v-if="$route.name === 'Staff'"></Overview>
          <router-view  :key="$route.fullPath"/>
      </template>
    </v-main>
  </v-app>
</template>

<script>
import Navigation from '../../../Navigation_modules/CustomNavigationDrawer'
import SiderBarItems from '../../../Navigation_modules/SideBarItems'
import Overview from '../Home/Overview.vue'

export default {
    name:"LecturerDashboard",
    components: { Navigation, Overview, SiderBarItems},
    data() {
        return {
          side_nav_items: [],
        }
    },
    created() {
      this.setSidenavMenu(this.userStore.getSubdomain);
    },

    methods:{
      setSidenavMenu(subdomain){
        this.side_nav_items= [
          { text: 'Home', icon: 'dashboard', route: '' , permissions: []},
            // { text: "Announcements", icon: "assignments", route: "announcements", permissions: []},
        ];
      }
    }

}
</script>

<style>

</style>
