import Csrf from "../apis/Csrf";
import Api from './Api';


export default {
    async createFaculty(newFacultyForm) {
        await Csrf.getCookie();
        return Api.post('/faculty/create', newFacultyForm);
    },

    async getAllFaculties() {
        await Csrf.getCookie();
        return Api.get('/faculties');
    },

    async getFacultyByID(id) {
        await Csrf.getCookie();
        return Api.get('/faculty/' + id);
    },

    async updateFaculty(id, updateData) {
        await Csrf.getCookie();
        return Api.patch('/faculty/update/' + id, updateData);
    },

    async deleteFaculty(id) {
        await Csrf.getCookie();
        return Api.patch('/faculty/destroy/' + id);
    },

    async getFacultyCount() {
        await Csrf.getCookie();
        return Api.get('/count-total-faculty');
    },
    
    async loadPage(page, per_page) {
        await Csrf.getCookie();
        return Api.get('/faculties/load-page/' + per_page + '?page=' + page);
    },
    async getDeanDashboardCountsByFacultyID(id) {
        await Csrf.getCookie();
        return Api.get('/faculty/dean/dashboard/counts/' + id);
    },
    
    // api for deans and subdeans----------------------------------------------
    async appointDean(id, appointDeanForm) {
        await Csrf.getCookie();
        return Api.post('/appoint/dean/faculty/' + id, appointDeanForm);
    },
    async getDean(id) {
        await Csrf.getCookie();
        return Api.get('/get/dean/faculty/' + id);
    },
    async appointSubDean(id, appointSubDeanForm) {
        await Csrf.getCookie();
        return Api.post('/appoint/subdean/faculty/' + id, appointSubDeanForm);
    },
    async getSubDean(id) {
        await Csrf.getCookie();
        return Api.get('/get/subdean/faculty/' + id);
    },

    async getFacultyStaffWithCurrentAppointment(id) {
        await Csrf.getCookie();
        return Api.get('/get/current-faculty-appointment/' + id);
    },

    async appointRoleToFaculty(id, form_data) {
        await Csrf.getCookie();
        return Api.post('/appoint/faculty-role/' + id, form_data);
    },

    async getExamOfficers(id) {
        await Csrf.getCookie();
        return Api.get('/faculty/exam-officers/' + id);
    },

    async getCurrentExamOfficer(id) {
        await Csrf.getCookie();
        return Api.get('/faculty/current-exam-officer/' + id);
    },

    async appointExamOfficer(appointHODForm) {
        await Csrf.getCookie();
        return Api.patch('/faculty/appointExamOfficer', appointHODForm);
    },
    
    
}
        