import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
// import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';


Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary:"#90EE90",//"#0c8953",//"#90EE90",//"#82cc99",//"#90EE90",//"#0C8953", //"#28333E", //"#FFCDD2",  //"#FED030",// //, // /
                secondary:"#D1B173",// "E2C094", //"#FFD700",    //"#FFCDD2", //"#D1B173", //"#405264", //"#E53935",  //"#0C8953",// // // 
                 //appbar:"#7D9B84", // #FFCDD2
                accent: "#2A55CB",//"#FFD700", //"#3F51B5",     //"#2A55CB",//colors.indigo.base, // 
                //accent-1:"#2A55CB",
                tertiary:"#176184",//"E2C094", //"#FFD700", //"#176184"
            },
        },
    },
});
