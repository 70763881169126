<template>
  <v-container>
    <div class="py-5">
        <h3 class="headline font-weight-bold text-center mb-5">Enrollment Complete</h3>
        <p class="text-center m-0 font-weight-medium body-1">You have successfully enrolled <strong>{{enrollmentData.institution_name}}</strong> on SchoolTry Tertiary</p>
        <p class="text-center m-0 font-weight-medium body-1">Your login credentials have been sent to <strong>{{enrollmentData.super_admin_email}}</strong></p>
        <p class="text-center m-0 font-weight-medium body-1">Thank you for using SchoolTry Tertiary!</p>
        <div class="text-center p-2">
          <v-btn color="primary" to="subDomainUrl">Go to Login</v-btn>
        </div>
        <div class="py-5 mt-4">
          <div class="text-center">
            <svg width="41" height="33" viewBox="0 0 41 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.149414 32.4027V15.0603C1.84165 14.5315 3.96317 14.2873 6.81572 14.53L6.7675 10.8577L11.4041 10.6166L11.3076 7.28349C13.3063 6.39227 15.6207 5.75923 19.0378 6.02831L20.2448 0.617188L21.4051 5.68924C24.0243 5.99876 26.5938 6.50736 29.0855 7.33171L29.1819 10.7131L33.7718 10.6649L33.82 14.53C36.0411 14.2547 38.2637 14.4491 40.4863 15.2065L40.6792 32.1616C33.4032 30.474 26.684 30.6622 20.4376 32.4509C14.1135 30.6202 7.37565 30.4974 0.149414 32.4027ZM23.5359 17.3125H26.4445V29.2608H23.5359V17.3125ZM19.03 17.3125H21.9401V29.2608H19.03V17.3125ZM23.5359 12.0383H26.4445V16.6577H23.5359V12.0383ZM19.03 12.0383H21.9401V16.6577H19.03V12.0383ZM14.5257 17.3125H17.4342V29.2608H14.5257V17.3125ZM14.5257 12.0383H17.4342V16.6577H14.5257V12.0383ZM34.2011 24.2992H36.9385V28.4054H34.2011V24.2992ZM34.2011 19.3376H36.9385V23.4438H34.2011V19.3376ZM30.9504 24.2992H33.6878V28.4054H30.9504V24.2992ZM30.9504 19.3376H33.6878V23.4438H30.9504V19.3376ZM6.9977 24.2992H9.73514V28.4054H6.9977V24.2992ZM6.9977 19.3376H9.73514V23.4438H6.9977V19.3376ZM3.74542 24.2992H6.48287V28.4054H3.74542V24.2992ZM3.74542 19.3376H6.48287V23.4438H3.74542V19.3376Z" fill="#FED030"/>
            </svg>
            <br>
            <svg width="76" height="14" viewBox="0 0 76 14" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-top: -15px;">
            <path d="M0.988904 11.7123L4.38116 3.59325L5.92253 3.3335C19.5662 1.02689 31.7136 -1.02619 38.3551 2.03944C42.8314 -0.749335 52.89 0.785811 65.4185 2.69891C67.5369 3.02243 69.7237 3.35683 71.7115 3.64769L72.2574 3.72701L75.8861 12.7995L74.4676 12.787C70.2245 12.7544 65.4231 11.9331 60.6419 11.115C51.8479 9.61252 43.1316 8.12248 39.013 11.6874L38.4826 12.1462L37.8869 11.776C31.1382 7.58588 19.1167 9.83494 6.96774 12.1073C5.17907 12.4417 3.38728 12.7761 2.03722 13.0188L0.31543 13.3267L0.988904 11.7123ZM5.73433 5.29793L3.42617 10.8273C4.57869 10.6157 5.59901 10.4244 6.61622 10.2347C18.8088 7.9545 30.8753 5.69922 38.31 9.81627C43.2374 6.20627 52.0625 7.71497 60.9623 9.23612C65.1385 9.95004 69.3302 10.6655 73.0522 10.8444L70.8965 5.45502C68.7983 5.14551 66.9396 4.86243 65.1354 4.58713C52.5633 2.66625 42.567 1.14044 39.0239 3.89499L38.5386 4.27139L37.9942 3.98365C32.0512 0.849583 19.9146 2.90111 6.23671 5.21239L5.73433 5.29793Z" fill="#FED030"/>
            </svg>
          </div>
          <div>
            <h3 class="headline font-weight-black text-h4 text-center m-0">Schooltry</h3>
            <p class="text-center px-6 font-weight-medium body-1">Tertiary</p>
          </div>
        </div>
    </div>
  </v-container>
</template>

<script>
export default {
name:"EnrollmentACK",
  props:['enrollmentData'],
  data() {
    return {
       institution:'',
       email:'',
       app_url: "",
    }
  },
  methods: {
    goToSubdomain() {

    }
  },
  mounted() {
    this.institution = localStorage.getItem("institution");
    this.email = localStorage.getItem("adminEmail");
    this.app_url = this.institution.portal_domain;
  },
  computed: {
    subDomainUrl() {
      let http_index = this.app_url.indexOf('//')
      if(http_index !== -1 && !_.isEmpty(this.enrollmentData.subdomain)) {
        return this.app_url.slice(0, (http_index+2)) + this.enrollmentData.subdomain + this.app_url.slice((http_index+3))
      } else
        return false
    }
  }
}
</script>

<style>

</style>
