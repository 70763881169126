<template>
    <div>
        <h3 class="headline font-weight-bold text-center">Institution Enrollment Form</h3>
        <h3 class="headline text-center">Please complete and submit this to enroll your institution on SchoolTry Tertiary</h3>
        <v-row class="pa-5">
            <v-col cols="12" sm="12" md="3">
                <input ref="fileInput" type="file" accept="image/*" hidden @input="previewImage"/>
                <v-card>
                    <v-img v-if="enrollmentData.institution_logo || preview"
                        :src="enrollmentData.institution_logo"
                        height="140"
                        aspect-ratio="1.7"
                        cover
                    ></v-img>
                    <v-img v-else
                        src="/images/school-try-logo.png"
                        height="140"
                        class="white"
                        aspect-ratio="1.4"
                        contain
                    ></v-img>
                    <v-card-actions class="p-0">
                        <v-row no-gutters>
                            <v-col class="p-0">
                                <v-btn block @click="uploadImage">
                                    Upload
                                    <v-icon right>mdi-cloud-upload</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
                <div v-if="errors.has('institution_logo')" class="text-left error--text">
                    {{ errors.get("institution_logo") }}
                </div>
            </v-col>
            <v-col cols="12" sm="12" md="9">
                 <v-row>
                     <v-col sm="6" md="6">
                        <label class="font-weight-bold">Institution's Category <span class="text-danger">*</span></label>
                        <v-autocomplete
                            outlined
                            v-model="enrollmentData.category"
                            :items="institutionCategories"
                            hide-details="auto"
                            label="Select Category"
                            :rules="[rules.required]"
                            @change="errors.clear('category')"
                        ></v-autocomplete>
                        <div v-if="errors.has('category')" class="text-left error--text">
                            {{ errors.get("category") }}
                        </div>
                    </v-col>
                    <v-col sm="6" md="6">
                        <label class="font-weight-bold">Institution's Ownership <span class="text-danger">*</span></label>
                        <v-autocomplete
                            outlined
                            v-model="enrollmentData.ownership"
                            :items="institutionOwnership"
                            hide-details="auto"
                            label="Select Ownership"
                            :rules="[rules.required]"
                            @change="errors.clear('ownership')"
                        ></v-autocomplete>
                        <div v-if="errors.has('onership')" class="text-left error--text">
                            {{ errors.get("onership") }}
                        </div>
                    </v-col>
                    <v-col sm="6" md="6" >
                        <label class="font-weight-bold">Institution's Name <span class="text-danger">*</span></label>
                        <custom-input
                            input-type="text"
                            input-label="Institution's full name*"
                            :input-val="enrollmentData.institution_name"
                            input-id="institution_name_field"
                            error-name="institution_name"
                            :error-obj="errors"
                            @input="enrollmentData.institution_name = $event"
                            rule-name="required_input"
                        />
                    </v-col>

                    <v-col sm="6" md="6" lg="4">
                        <label class="font-weight-bold">Institution's Accronym <span class="text-danger">*</span></label>
                        <custom-input
                            input-type="text"
                            input-label="e.g., ABU, Unilorin, OAU, UDUS,... *"
                            :input-val="enrollmentData.institution_accronym"
                            input-id="institution_accronym_field"
                            error-name="institution_accronym"
                            :error-obj="errors"
                            @input="enrollmentData.institution_accronym = $event"
                            rule-name="required_input"
                        />
                    </v-col>

                    <v-col sm="12" md="4" lg="4">
                        <label class="font-weight-bold">Motto</label>
                        <custom-input
                            input-type="text"
                            input-label="Motto*"
                            :input-val="enrollmentData.institution_motto"
                            input-id="institution_address_field"
                            error-name="institution_address"
                            :error-obj="errors"
                            @input="enrollmentData.institution_motto = $event"
                            rule-name="required_input"
                        />
                    </v-col>

                    <v-col sm="12" md="4" lg="4">
                        <label class="font-weight-bold"> Country <span class="text-danger">*</span></label>
                        <v-autocomplete
                            outlined
                            v-model="enrollmentData.institution_country"
                            :items="all_countries"
                            label="Select Country"
                            hide-details="auto"
                            :rules="[rules.required]"
                            @change="getCountryCities(enrollmentData.institution_country)"
                        ></v-autocomplete>
                        <div v-if="errors.has('institution_country')" class="text-left error--text">
                            {{ errors.get("institution_country") }}
                        </div>
                    </v-col>

                    <v-col sm="12" md="4" lg="4">
                        <label class="font-weight-bold">City/Town <span class="text-danger">*</span></label>
                        <v-autocomplete
                            outlined
                            v-model="enrollmentData.institution_city"
                            :items="country_cities"
                            label="Select City/Town"
                            hide-details="auto"
                            :disabled="!enrollmentData.institution_country"
                            :rules="[rules.required]"
                            @change="errors.clear('institution_city')"
                        ></v-autocomplete>
                        <div v-if="errors.has('institution_city')" class="text-left error--text">
                            {{ errors.get("institution_city") }}
                        </div>
                    </v-col>

                    <v-col sm="12" md="12">
                        <label class="font-weight-bold">Institution's Address <span class="text-danger">*</span></label>
                        <custom-input
                            input-type="text"
                            input-label="Street address*"
                            :input-val="enrollmentData.institution_address"
                            input-id="institution_address_field"
                            error-name="institution_address"
                            :error-obj="errors"
                            @input="enrollmentData.institution_address = $event"
                            rule-name="required_input"
                        />
                    </v-col>

                    <v-col sm="6" md="6">
                        <label class="font-weight-bold">Institution's Email <span class="text-danger">*</span></label>
                        <custom-input
                            input-type="email"
                            input-label="Institution's email address*"
                            :input-val="enrollmentData.institution_email"
                            input-id="institution_email_field"
                            error-name="institution_email"
                            :error-obj="errors"
                            @input="enrollmentData.institution_email = $event"
                            rule-name="email_rules"
                        />
                    </v-col>

                    <v-col sm="6" md="6">
                        <label class="font-weight-bold">Confirm Institution's Email <span class="text-danger">*</span></label>
                        <custom-input
                            input-type="email"
                            input-label="Re-type email address*"
                            :input-val="enrollmentData.institution_email_confirmation"
                            input-id="institution_email_confirmation_field"
                            error-name="institution_email_confirmation"
                            :error-obj="errors"
                            @input="enrollmentData.institution_email_confirmation = $event"
                            rule-name="email_rules"
                        />
                    </v-col>

                    <v-col sm="6" md="6">
                        <label class="font-weight-bold">Institution's phone number <span class="text-danger">*</span></label>
                        <!-- <vue-phone-number-input v-model="enrollmentData.institution_phone"
                                                default-country-code="NG"
                                                :only-countries=phoneCountryList
                                                :required=phoneNumberRequired
                        >
                        </vue-phone-number-input> -->
                        <v-text-field
                                outlined
                                v-model="enrollmentData.institution_phone"
                                label="Institution's phone number"
                                hide-details="auto"
                                :rules="[rules.required]"
                                :prefix="countryPhoneCode"
                                @input="errors.clear('institution_phone')"
                            ></v-text-field>
                        <div v-if="errors.has('institution_phone')" class="text-left error--text">
                        {{ errors.get("institution_phone") }}
                        </div>
                    </v-col>

                    <v-col sm="6" md="6">
                        <label class="font-weight-bold">Preferred subdomain <span class="text-danger">*</span>                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                color="primary"
                                dark
                                >
                                mdi-help-circle
                                </v-icon>
                            </template>
                                <span>
                                <v-alert>
                                    This is required to create a unique subdomain account for your institution on schooltry tertiary.
                                    <br>
                                    For example, if you enter 'abu', your unique subdomain will be 'abu.schooltry-tertiary.com'
                                <!--     <br>
                                    If you do not fill this field, your accronym will be used to generate your subdomain.-->
                                </v-alert>
                                </span>
                            </v-tooltip>
                        </label>
                            <custom-input
                                input-type="text"
                                input-label="Specify a subdomain*"
                                :input-val="enrollmentData.subdomain"
                                input-id="subdomain_field"
                                error-name="subdomain"
                                :error-obj="errors"
                                @input="enrollmentData.subdomain = $event"
                                rule-name="required_input"
                            />
                    </v-col>


                </v-row>
            </v-col>
        </v-row>

        <div class="text-right pa-3">
            <v-btn color="primary" @click="$emit('validateInstitutionData')">Next</v-btn>
        </div>
    </div>
</template>
<script>
import CustomInput from '../../CustomInput'
import CountryData from '../../../apis/Country'
import NationalityAPI from '../../../apis/Nationality'

export default {
    props: ['enrollmentData','errors'],
    components: { CustomInput },
    data() {
        return {
            institutionCategories:['University', 'Polytechnic', 'Monotechnic', 'College of Education'],
            institutionOwnership:['Public', 'Private'],
            phoneCountryList:['NG'],
            phoneNumberRequired:true,
            preview: null,
            validationErrors:[],
            rules: {
                required: value => !!value || 'Required.',
                email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'A valid e-mail address required.'
                    },
            },

            all_countries: [],
            country_cities:[],
        }
    },
    mounted() {
        this.getAllCountries();
    },
    methods: {
        uploadImage() {
            this.$refs.fileInput.click()
        },
        getAllCountries() {
            this.all_countries = NationalityAPI.getCountryList();
        },
        getCountryCities(country) {
            this.country_cities = [];
            this.country_cities = NationalityAPI.getCityListByCountryName(country);
        },
        previewImage() {
            this.errors.clear('institution_logo')
            let input = this.$refs.fileInput
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.preview = e.target.result
                    this.enrollmentData.institution_logo = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        }
    },
    computed: {
      countryPhoneCode() {
        if (!_.isEmpty(this.enrollmentData.institution_country))
            return NationalityAPI.getDialCodeByCountryName(this.enrollmentData.institution_country);
      }
    }
}
</script>
