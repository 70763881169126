<template>
  <div>
    <v-text-field
        :type="inputType"
        :label="inputLabel"
        v-model="bindedValue"
        @input="errorObj.clear(errorName)"
        outlined
        hide-details="auto"
        :rules="rules[ruleName]"
        :id="inputId"
        :readonly="readonly"
    ></v-text-field>
    <div v-if="errorObj.has(errorName)" class="text-left error--text">
      {{ errorObj.get(errorName) }}
    </div>
  </div>
</template>

<script>
import {Errors} from "../classes/Errors";

export default {
  name: "GoInput",
  props: {
    inputLabel: {
      type: String
    },
    inputType: {
      type: String
    },
    ruleName: {
      type: String
    },
    inputClass: {
      type: String
    },
    inputVal: {
      type: [Number, String, Boolean]
    },
    errorObj: {
      type: Object,
      default: () => (new Errors())
    },
    errorName: {
      type: String,
      default: 'NA'
    },
    inputId: {
      type: String
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rules: {
        required_input: [(v) => !!v || "this value is required"],
        validate_phone: this.validatePhone(),
        email_rules: this.getEmailRules(),
        // nin_rules: [
        //   (v) => !!v || "NIN is required",
        //   (v) => (v && v.length === 11) || "NIN can only contain 11 numbers",
        // ],
        // nin_rules_optional: [
        //   (v) => (v && v.length === 11) || "NIN can only contain 11 numbers",
        // ],
        no_label_phone_number_rule: [
          (v) => !!v || "PHONE NUMBER is required",
          (v) => (v && v.length === 11) || "Can only contain 11 numbers",
        ],
        optional_phone_number_rule: [
          (v) => (v && v.length === 11) || "Can only contain 11 numbers",
        ],
      },
    };
  },
  methods: {
    validatePhone() {
      let rules = [];
      this.inputLabel === "Phone Number*"
          ? (rules = [
            (v) => !!v || "Phone Number required",
            (v) =>
                (v && v.length === 11) || "Number can only contain 11 numbers",
          ])
          : rules;
      return rules;
    },
    getEmailRules() {
      let rules = [];
      if (this.inputLabel === "Email*") {
        rules = [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ];
      } else if (this.inputLabel === "Email") {
        rules = [(v) => !v || /.+@.+/.test(v) || "E-mail must be valid"];
      }
      return rules;
    },
  },
  computed: {
    bindedValue: {
      get() {
        return this.inputVal;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
