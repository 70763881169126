<template>
<div>
    <h3 class="headline font-weight-bold text-center">Enter Admin Personal Details</h3>

    <v-row class="pa-5">
        <v-col cols="12" sm="12" md="3">
           <input ref="fileInput" type="file" accept="image/*" hidden @input="previewImage"/>
            <v-card>
                <v-img v-if="enrollmentData.staff_image || preview"
                    :src="enrollmentData.staff_image"
                    height="140"
                    aspect-ratio="1.7"
                    cover
                ></v-img>
                <v-img v-else
                    src="/images/profile_general.png"
                    height="140"
                    class="white"
                    aspect-ratio="1.4"
                    contain
                ></v-img>
                <v-card-actions class="p-0">
                    <v-row no-gutters>
                        <v-col class="p-0">
                            <v-btn block @click="uploadImage">
                                Upload
                                <v-icon right>mdi-cloud-upload</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
            <div v-if="errors.has('staff_image')" class="text-left error--text">
                {{ errors.get("staff_image") }}
            </div>
        </v-col>
        <v-col cols="12" sm="12" md="9">
                <v-row>
                <v-col sm="6" md="6" lg="4">
                    <label class="font-weight-bold">Staff ID <span class="text-danger">*</span></label>
                    <custom-input
                        input-type="text"
                        input-label="Enter your institution-based staff ID*"
                        :input-val="enrollmentData.staff_number"
                        input-id="staff_number_field"
                        error-name="staff_number"
                        :error-obj="errors"
                        @input="enrollmentData.staff_number = $event"
                        rule-name="required_input"
                    />
                </v-col>

                <v-col sm="6" md="6" lg="4">
                    <label class="font-weight-bold">First Name <span class="text-danger">*</span></label>
                    <custom-input
                        input-type="text"
                        input-label="Enter your first name*"
                        :input-val="enrollmentData.firstname"
                        input-id="firstname_field"
                        error-name="firstname"
                        :error-obj="errors"
                        @input="enrollmentData.firstname = $event"
                        rule-name="required_input"
                    />
                </v-col>

                <v-col sm="6" md="6" lg="4">
                    <label class="font-weight-bold">Last Name <span class="text-danger">*</span></label>
                    <custom-input
                        input-type="text"
                        input-label="Enter your last name*"
                        :input-val="enrollmentData.lastname"
                        input-id="lastname_field"
                        error-name="lastname"
                        :error-obj="errors"
                        @input="enrollmentData.lastname = $event"
                        rule-name="required_input"
                    />
                </v-col>

                <v-col sm="6" md="6" lg="4">
                    <label class="font-weight-bold">Phone number <span class="text-danger">*</span></label>
                    <v-text-field
                        outlined
                        v-model="enrollmentData.phone"
                        label="Enter your phone number"
                        hide-details="auto"
                        :rules="[rules.required]"
                        :prefix="countryPhoneCode"
                        @input="errors.clear('phone')"
                    ></v-text-field>
                    <div v-if="errors.has('phone')" class="text-left error--text">
                    {{ errors.get("phone") }}
                    </div>
                </v-col>

                <v-col sm="6" md="6" lg="4">
                    <label class="font-weight-bold">Email <span class="text-danger">*</span></label>
                    <custom-input
                        input-type="text"
                        input-label="Enter your official email address*"
                        :input-val="enrollmentData.super_admin_email"
                        input-id="super_admin_email_field"
                        error-name="super_admin_email"
                        :error-obj="errors"
                        @input="enrollmentData.super_admin_email = $event"
                        rule-name="email_rules"
                    />
                </v-col>

                <v-col sm="6" md="6" lg="4">
                    <label class="font-weight-bold">Confirm Your Email <span class="text-danger">*</span></label>
                    <custom-input
                        input-type="text"
                        input-label="Enter your official email address*"
                        :input-val="enrollmentData.super_admin_email_confirmation"
                        input-id="super_admin_email_confirmation_field"
                        error-name="super_admin_email_confirmation"
                        @input="enrollmentData.super_admin_email_confirmation = $event"
                        rule-name="email_rules"
                    />
                </v-col>

            </v-row>
        </v-col>
    </v-row>

    <v-row class="px-5">
        <v-container fluid>
            <v-checkbox v-model="enrollmentData.terms_conditions" :rules="[rules.required]">
                <template v-slot:label>
                    <div>
                        I have read and agreed to the terms and conditions of SchoolTry Tertiary
                    </div>
                </template>
            </v-checkbox>
            <div v-if="errors.has('terms_conditions')" class="text-left error--text">
                {{ errors.get("terms_conditions") }}
            </div>
        </v-container>
    </v-row>

    <div class="text-right pa-3">
        <v-btn outlined @click="$emit('goBackStep')" class="mr-3">Back</v-btn>
        <v-btn color="primary" @click="$emit('submitInstitutionData')">Enroll</v-btn>
    </div>

</div>
</template>
<script>
import CustomInput from '../../CustomInput'
import NationalityAPI from '../../../apis/Nationality'

export default {
    props: ['enrollmentData','errors'],
    components: { CustomInput },
    data() {
        return {
            phoneCountryList:['NG'],
            preview: null,
            rules: {
                required: value => !!value || 'Required.',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'A valid e-mail address required.'
                },
            },
        }
    },
    methods: {
        uploadImage() {
            this.$refs.fileInput.click()
        },
        previewImage() {
            let input = this.$refs.fileInput
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.preview = e.target.result
                    this.enrollmentData.staff_image = e.target.result
                }
                reader.readAsDataURL(file[0])
                this.$emit('input', file[0])
            }
        }
    },
    computed: {
      countryPhoneCode() {
        if(!_.isEmpty(this.enrollmentData.institution_country))
            return NationalityAPI.getDialCodeByCountryName(this.enrollmentData.institution_country);
      }
    }
}
</script>
