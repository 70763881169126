<template>
    <v-container class="d-flex justify-center">
      <v-alert
        v-show="show"
        type="success"
        elevation="2"
        @click="dismiss"
      >
      Congratulations, {{student_name}}! You have graduated with {{ class_of_degree }}!  🎓
      </v-alert>
    </v-container>
  </template>

  <script>
  export default {
    props: ['student_name', 'class_of_degree'],
    data() {
      return {
        show: true,
      };
    },
    methods: {
      dismiss() {
        this.show = false;
      },
    },
  };
  </script>

  <style scoped>
  /* .moving-alert {
    animation: move 5s infinite;
  }

  @keyframes move {
    0% {
      transform: translateX(-100%);
    }
    50% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  } */
  </style>
