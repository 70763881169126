import Api from './Api';
import Csrf from "./Csrf";


export default {

    async validateInstitutionData(institutionData) {
       return Api.post('/institution/validate', institutionData);
    },

    async getGeneralAcceptanceFeeDeadline() {
        await Csrf.getCookie();
        return Api.get('/candidate/general-acceptance-deadline');
    },

    async getOpenedAdmissionGeneralAcceptanceDeadlines() {
        await Csrf.getCookie();
        return Api.get('/institution/open-admissions/general-acceptance-deadline');
    },

    async enrollInstitution(enrollmentForm) { //Enroll an institution
        await Csrf.getCookie();
        return Api.post('/institution/enroll', enrollmentForm);
    },

    async enrollAdministrator(registrationForm) {
        await Csrf.getCookie();
        return Api.post('/admin/enroll', registrationForm);
    },

    async getInstitution() {
        await Csrf.getCookie();
        return Api.get('/get-institution');
    },

    async verifySubdomain() {
        await Csrf.getCookie();
        return Api.get('/institution/subdomain/verify');
    },

    async getInstitutionData() {
        await Csrf.getCookie();
        return Api.get('/get-institution-data');
    },

    async updateInstitutionCulturalSettings({ id, payload }) {
        await Csrf.getCookie();
        return Api.patch(`/institutions/${id}/cultural-settings`, payload);
    }


};
