import Api from './Api';
import Csrf from "./Csrf";

export default {
    async createInstitute(newInstituteForm) {
        await Csrf.getCookie();
        return Api.post('/institute/create', newInstituteForm);
    },

    async getInstitutes(page=1) {
        await Csrf.getCookie();
        return Api.get('/institutes?page=' + page);
    },

    async getInstitute(id) {
        await Csrf.getCookie();
        return Api.get('/institute/' + id);
    },

    async getInstituteByAcronym(acronym) {
        await Csrf.getCookie();
        return Api.get('/institute/acronym/' + acronym);
    },

    async getCounts(id){
        await Csrf.getCookie();
        return Api.get('/institute/counts/' + id);
    },

    async getCordinator(id) {
        await Csrf.getCookie();
        return Api.get('/institute/cordinator/' + id);
    },

    async setCordinator(id, appointCordinatorForm) {
        await Csrf.getCookie();
        return Api.patch('/institute/cordinator/' + id, appointCordinatorForm);
    },

    async getInstituteCount() {
        await Csrf.getCookie();
        return Api.get('/count-total-institutes');
    },

    async getAllInstituteProgrammes(page) {
        await Csrf.getCookie();
        return Api.get('/all-institute/programmes?page=' + page);
    },

    async updateInstitute(id, updateData) {
        await Csrf.getCookie();
        return Api.patch('/institute/update/' + id, updateData);
    },
    async deleteInstitute(id) {
        await Csrf.getCookie();
        return Api.patch('/institute/destroy/' + id);
    },
};