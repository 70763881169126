<template>
  <div class="container mx-md-70 mx-3 my-4">
    <div>
      <v-list-item two-line v-if="authenticatedUser">
        <v-list-item-content>
          <v-list-item-title class="headline">{{ authenticatedUser.designated_hostel.name }}</v-list-item-title>
          <v-list-item-title>{{ authenticatedUser.institution.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>

    <!-- <OverviewCards></OverviewCards> -->
  </div>
</template>

<script>
import OverviewCards from "./OverviewCards";
import UserAPI from "../../../apis/User";

export default {
  name: "Overview",
  components: {
    OverviewCards,
  },
  data() {
    return {
      authenticatedUser: null,
    };
  },

  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {
      UserAPI.auth().then((response) => {
        //retrieve the institution_id of the current user
        this.authenticatedUser = response.data.data;
      });
    },
  },
};
</script>
