<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <Card
          title="Students"
          ic="students"
          link="/department-exam-officer/students"
          :total="this.totalStudents"
        />
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <Card
          title="Staff"
          ic="staff"
          link="/department-exam-officer/staff"
          :total="this.totalStaff"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="6">
        <Card
          title="Programmes"
          ic="programmes"
          link="/department-exam-officer/programmes"
          :total="this.totalProgrammes"
        />
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <Card
          title="Courses"
          ic="courses"
          link="/department-exam-officer/courses"
          :total="this.totalCourses"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DepartmentAPI from "../../../../apis/Department";
import UserAPI from "../../../../apis/User";
import Card from "../../../Card";

export default {
  name: "OverviewCards",
  components: {
    Card,
  },
  data() {
    return {
      totalStaff: 0,
      totalStudents: 0,
      totalProgrammes: 0,
      totalCourses: 0,
    };
  },

  methods: {
    initialize() {
      UserAPI.auth().then((response) => {
        DepartmentAPI.getHODDashboardCountsByDepartmentID(
          response.data.data.designated_department.id
        ).then((response) => {
          this.totalStaff = response.data.staffCount;
          this.totalStudents = response.data.studentCount;
          this.totalProgrammes = response.data.programmeCount;
          this.totalCourses = response.data.courseCount;
        });
      });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style>
</style>