import Api from './Api';
import Csrf from "./Csrf";


export default {
    async createLevelAdvising(newLevelAdvisingForm) {
        await Csrf.getCookie();
        return Api.post('/levelAdvising/create', newLevelAdvisingForm);
    },

    async getAllLevelAdvisings() {
        await Csrf.getCookie();
        return Api.get('/levelAdvisings');
    },

    async getMyCurrentLevelAdvisings(id) {
        await Csrf.getCookie();
        return Api.get('/levelAdvisings/current/' + id);
    },

    async getAllLevelAdvisingss(data) {
        await Csrf.getCookie();
        return Api.post('/levelAdvisingss', data);
    },

    async getLevelAdvisingsByDepartmentID(id) {
        await Csrf.getCookie();
        return Api.get('/levelAdvisings/department/' + id);
    },
    async getLevelAdvisingsByFacultyID(id) {
        await Csrf.getCookie();
        return Api.get('/levelAdvisings/by-faculty/' + id);
    },

    async getDepartmentalStudentCourseRegistrations(data) {
        await Csrf.getCookie();
        return Api.post('/levelAdvisings/student-course-registrations', data);
    },

    async downloadCourseRegistrationReport(department_id, level_id, programme_id) {
        return Api.get('/levelAdvisings/student-course-registrations/downloadReport/' + department_id + '/' + level_id + '/' + programme_id, {responseType: 'blob'});
    },

    async getStudentCourseRegistrations(student_id) {
        await Csrf.getCookie();
        return Api.get('/levelAdvisings/student-course-reg/' + student_id);
    },

    async updateLevelAdvising(id, updateData) {
        await Csrf.getCookie();
        return Api.patch('/levelAdvising/update/' + id, updateData);
    },
    async deleteLevelAdvising(id) {
        await Csrf.getCookie();
        return Api.patch('/levelAdvising/destroy/' + id);
    },

    async approveCourseRegistration(id) {
        await Csrf.getCookie();
        return Api.patch('/levelAdvising/approve-course-registration/' + id);
    },

    async rejectCourseRegistration(id, remark) {
        await Csrf.getCookie();
        return Api.patch('/levelAdvising/reject-course-registration/' + id + "/" + remark);
    },

    async approveAllCourses(id) {
        await Csrf.getCookie();
        return Api.patch('/levelAdvising/approve-all-courses/' + id );
    },

    async getProgrameStudentsCountByLevel(data) {
        await Csrf.getCookie();
        return Api.post('/levelAdvisings/students-count', data);
    },

    async getProgrameStudentsByLevel(data) {
        await Csrf.getCookie();
        return Api.post('/levelAdvisings/students', data);
    },
};
