<template>
  <div class="container mx-md-70 mx-3 my-4">
    <div>
      <v-list-item two-line v-if="appointment">
        <v-list-item-content>
          <v-list-item-title class="headline">{{
            appointment.name
          }}</v-list-item-title>
          <!-- <v-list-item-title>{{
            authenticatedUser.designated_department.faculty.name
          }}</v-list-item-title> -->
        </v-list-item-content>
      </v-list-item>
    </div>

    <OverviewCards></OverviewCards>
  </div>
</template>

<script>
import OverviewCards from "./OverviewCards";
import UserAPI from "../../../apis/User";

export default {
  name: "Overview",
  components: {
    OverviewCards,
  },
  data() {
    return {
      authenticatedUser: null,
      appointment: [],
    };
  },

  mounted() {
    const storedInstitute = sessionStorage.getItem('appointment');
    this.appointment = JSON.parse(storedInstitute);
    this.initialize();
  },

  methods: {
    initialize() {},
  },
};
</script>
