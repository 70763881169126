// colorUtils.js

export function hexToRgb(hex) {
  hex = hex.replace('#', '');
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  return { r, g, b };
}

export function rgbToLuminance(r, g, b) {
  r = r / 255;
  g = g / 255;
  b = b / 255;
  
  r = (r <= 0.03928) ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
  g = (g <= 0.03928) ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
  b = (b <= 0.03928) ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);
  
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

export function contrastRatio(hex1, hex2) {
  let rgb1 = hexToRgb(hex1);
  let rgb2 = hexToRgb(hex2);
  
  let lum1 = rgbToLuminance(rgb1.r, rgb1.g, rgb1.b);
  let lum2 = rgbToLuminance(rgb2.r, rgb2.g, rgb2.b);
  
  let brightest = Math.max(lum1, lum2);
  let darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
}

export function suitableTextColor(backgroundColor) {
  const white = '#FFFFFF';
  const black = '#000000';
  
  let contrastWithWhite = contrastRatio(backgroundColor, white);
  let contrastWithBlack = contrastRatio(backgroundColor, black);
  
  return contrastWithWhite > contrastWithBlack ? white : black;
}
