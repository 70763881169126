import Api from './Api';
export default {
    authCheck() {
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-undef
            Api.get('/user').then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        });
    }
}