
import Api from "./Api";
import Csrf from "./Csrf";

export default {
    async getTransfers(page, session_id) {
        await Csrf.getCookie();
        return Api.get("/transfer-requests" + "?page=" + page + '&session_id=' + session_id);
    },

    async getInboundTransfers(page, session_id, department_id) {
        await Csrf.getCookie();
        return Api.get("/transfer-requests/inbound" + "?page=" + page + '&session_id=' + session_id + '&department_id=' + department_id);
    },

    async getOutboundTransfers(page, session_id, department_id) {
        await Csrf.getCookie();
        return Api.get("/transfer-requests/outbound" + "?page=" + page + '&session_id=' + session_id + '&department_id=' + department_id);
    },

    async getPendingReleaseTransfers(page, session_id) {
        await Csrf.getCookie();
        return Api.get("/transfer-requests/pending-release" + "?page=" + page + '&session_id=' + session_id);
    },

    async getPendingAcceptanceTransfers(page, session_id) {
        await Csrf.getCookie();
        return Api.get("/transfer-requests/pending-acceptance" + "?page=" + page + '&session_id=' + session_id);
    },

    async getPendingApprovalTransfers(page, session_id) {
        await Csrf.getCookie();
        return Api.get("/transfer-requests/pending-approval" + "?page=" + page + '&session_id=' + session_id);
    },

    async CreateTransfer(formData) {
        await Csrf.getCookie();
        return Api.post("/transfer-requests/create", formData);
    },

    async releaseStudent(id, formData) {
        await Csrf.getCookie();
        return Api.post("/transfer-requests/" + id + '/release-student', formData);
    },

    async acceptStudent(id, formData) {
        await Csrf.getCookie();
        return Api.post("/transfer-requests/" + id + '/accept-student', formData);
    },

    async approveTransfer(id, formData) {
        await Csrf.getCookie();
        return Api.post("/transfer-requests/" + id + '/approve-transfer', formData);
    },

    async deleteTransfer(id) {
        await Csrf.getCookie();
        return Api.delete("/transfer-requests/" + id);
    },

    async updateRequest(id, formData) {
        await Csrf.getCookie();
        return Api.post("/transfer-requests/update/" + id, formData);
    },

    async checkIncompleteRequest(id) {
        await Csrf.getCookie();
        return Api.get("/transfer-requests/check-incomplete-request/" + id);
    }
};
