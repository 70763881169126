<template>
  <v-stepper :value="formStep" light>
    <v-stepper-header>
      <template v-for="(n,i) in titles">
          <v-stepper-step :step="i+1" :complete="formStep > (i+1)">
            {{ n }}
          </v-stepper-step>

          <v-divider
            v-if="(i+1) !== n.length"
            :key="n"
          ></v-divider>
        </template>
    </v-stepper-header>

    <v-stepper-items>

        <slot></slot>

    </v-stepper-items>
  </v-stepper>
</template>
<script>
export default {
  props: ['titles','formStep'],
}
</script>