<template>
  <v-container>
    <v-row class="justify-content-around">
      <v-col cols="12" sm="12" md="6">
        <Card
          title="Students"
          ic="students"
          link="/cordinator/students"
          :total="this.totalStudents"
        />
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <Card
          title="Staff"
          ic="staff"
          link="/cordinator/staff"
          :total="this.totalStaff"
        />
      </v-col>
    </v-row>

    <v-row class="justify-content-around">
      <v-col cols="12" sm="12" md="6">
        <Card
          title="Programmes"
          ic="programmes"
          link="/cordinator/programmes"
          :total="this.totalProgrammes"
        />
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <Card
          title="Courses"
          ic="courses"
          link="/cordinator/courses"
          :total="this.totalCourses"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CordinatorAPI from "../../../apis/Cordinator";
import UserAPI from "../../../apis/User";
import Card from "../../Card";

export default {
  name: "CordinatorOverviewCards",
  components: {
    Card,
  },
  data() {
    return {
      totalStaff: 0,
      totalStudents: 0,
      totalProgrammes: 0,
      totalCourses: 0,
      designated_unit: {},
    };
  },

  methods: {
    initialize() {
      CordinatorAPI.getDashboardCountsByHostID(
        this.designated_unit.id
      ).then((response) => {
        this.totalStaff = response.data.staffCount;
        this.totalStudents = response.data.studentCount;
        this.totalProgrammes = response.data.programmeCount;
        this.totalCourses = response.data.courseCount;
      });
    },
  },
  mounted() {
      const stored_unit = sessionStorage.getItem('designated_unit');
      this.designated_unit = JSON.parse(stored_unit);
      this.initialize();
    },
};
</script>

<style>
</style>
