<template>
  <v-app id="inspire">
      <!-- <v-main> -->
      <v-sheet  height="400" color="primary" dark>
        <!-- <br/>
        <h3>Institution Enrollment Form</h3>
        <h4>Please complete and submit this form to enroll your institution on SchoolTry Tertiary</h4> -->

        <v-container light>

        <!-------------------------------alert messages------------------------------>  
          <div class="alert alert-success" v-if="actionSuccessful">
              {{successMessage}}
          </div>            
        
          <v-form v-model="valid" class="mt-10" >
            
              <multi-step :titles="form_steps" :form-step="step">
                  <v-stepper-content step="1">
                      <!-------------------Institution's data------------------>
                      <institution-details @validateInstitutionData="validateInstitutionDetails" :enrollment-data="enrollmentData" :errors="errors" />
                  </v-stepper-content>
                  <v-stepper-content step="2">
                      <!-------------------Super Admin's data------------------>
                      <personal-details :enrollment-data="enrollmentData" :errors="errors" @submitInstitutionData="enroll" @goBackStep="backToStep(1)"/>
                  </v-stepper-content>
                  <v-stepper-content step="3">
                      <!-------------------Enrollment Complete------------------>
                      <enrollment-acknowledgement :enrollment-data="enrollmentData"></enrollment-acknowledgement>
                  </v-stepper-content>
              </multi-step>         

        </v-form>
       

        <v-overlay :value="overlay">
          <v-progress-circular
            indeterminate
            size="50"
          ></v-progress-circular>
        </v-overlay>

      </v-container>
    </v-sheet>
  </v-app>

</template>

<script>
import InstitutionAPI from "../../../apis/Institution"
import VueRecaptcha from 'vue-recaptcha'
import MultiStep from '../../MultiStep'
import InstitutionDetails from './InstitutionDetails'
import PersonalDetails from './PersonalDetails'
import EnrollmentAcknowledgement from "./EnrollmentAcknowledgement"
import { Errors } from '../../../classes/Errors';

export default {
    name:"EnrollmentMultiStep",
    components: { VueRecaptcha, MultiStep, InstitutionDetails, PersonalDetails, EnrollmentAcknowledgement },

    watch: {
      overlay (val) {
        val && setTimeout(() => {
          this.overlay = false
        }, 5000)
      },
    },
   
    data() {
        return {
            step: 1,
            valid: false,
            actionSuccessful:false,
            phoneCountryList:['NG'],
            phoneNumberRequired:true,
            agreed: false,
            overlay: false,
           // reCaptchaSiteKey: process.env.MIX_GOOGLE_RECAPTCHA_SITE_KEY,
            form_steps: ['Institutions Details','Admin Personal Details','Completed'],
            
            //--------------alert variables----------//
            error: {},
            successMessage:'',

            enrollmentData:{  //----------institution form data-----------//
                category:'',
                institution_name: '',
                institution_accronym: '',    
                subdomain: '',
                institution_email: '',
                institution_email_confirmation:'',
                institution_phone:'',
                institution_address:'',
                institution_city:'',
                institution_country:'',
                institution_motto:'',
                institution_logo:null,
                terms_conditions: false,

                //-----------admin form data----------------//
                staff_number:'',
                staff_image: null,
                firstname: '',
                lastname: '',
                super_admin_email: '',
                super_admin_email_confirmation:'',
                phone: '',
                usertype:'Admin',
                recaptcha:'',
            },
            errors: new Errors(),
        }
    },

//---------------- Methods--------------//
    methods: {
      switchComponent(comp) {     
        this.$router.push({name:comp});
      },

      onVerify(response) {
          this.enrollmentData.recaptcha = response;
          this.overlay = true;
          this.enroll(this.enrollmentData);
      },
      validateInstitutionDetails() {
        InstitutionAPI.validateInstitutionData(this.enrollmentData)
        .then(() => {
          this.step=2
        }).catch(error => {
            if(error.response.status === 422){
                this.errors.record(error.response.data.errors)
            }
        })
      },

      //  enroll(formData){     
      enroll(){ 
          this.overlay = true; 
          //  if (this.enrollmentData.recaptcha !== 'undefined') {
          InstitutionAPI.enrollInstitution(this.enrollmentData)
          .then(response=>{
            localStorage.setItem("institution", response.data.institution);                    
            localStorage.setItem("adminEmail", response.data.email);
            // this.$refs.recaptcha.reset();
            this.overlay = false;
            this.step=3
            // this.$router.push({name:"Enrolled"});
          })
          .catch(error=>{
            this.overlay = false;
            if(error.response.status === 422){
                this.errors.record(error.response.data.errors);
                this.validationErrors = Object.values(error.response.data.errors).flat();
            }
            if(error.response.status === 403){
              this.errors = error.response.data.errors;
              abort(403);
              alert("Unauthorized Access!");
            }
            else{
              console.log(error);
            }
          }
          );                      
        // }    
      },
      backToStep(step) {
        if(this.step > 0 && this.step < this.form_steps.length)
          this.step = step
      }
    }
}
</script>

<style>

</style>
