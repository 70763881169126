<template>

        <v-container style="margin-top:30px;" id="inspire">
            <v-row >
                <v-col >
                  <v-card outlined elevation="2" height="200" style=" font-family: 'Poppins', sans-serif!important;" color="#ffffff">
                    <v-list-item three-line class="pa-5">
                      <v-list-item-content  style="color:#2C2C2C">
                        <!-- <div class="overline mb-4">OVERLINE</div> -->
                        <v-list-item-title class="headline" color="primary" style=" font-family: 'Poppins', sans-serif!important;"> Courses </v-list-item-title>
                         <v-list-item-avatar rounded size="60">
                        <h2 style="color:#2C2C2C">30</h2>
                      </v-list-item-avatar>
                      </v-list-item-content>
                      <v-spacer></v-spacer>
                        <v-icon color="primary" class="mr-4" size="50">mdi-bookshelf</v-icon>

                    </v-list-item>

                  </v-card>
                </v-col>
                <v-col >
                  <v-card outlined elevation="2" height="200" style=" font-family: 'Poppins', sans-serif!important;" color="#ffffff">
                    <v-list-item three-line class="pa-5">
                      <v-list-item-content  style="color:#2C2C2C">
                        <!-- <div class="overline mb-4">OVERLINE</div> -->
                        <v-list-item-title class="headline" color="primary" style=" font-family: 'Poppins', sans-serif!important;"> Lectures </v-list-item-title>
                         <v-list-item-avatar rounded size="60">
                        <v-icon color="primary" size="50">mdi-monitor</v-icon>
                      </v-list-item-avatar>
                      </v-list-item-content>
                      <v-spacer></v-spacer>
                        <h2 style="color:#2C2C2C">30</h2>

                    </v-list-item>


                  </v-card>
                </v-col>
                <v-col >
                 <v-card outlined elevation="2" height="200" style=" font-family: 'Poppins', sans-serif!important;" color="#ffffff">
                    <v-list-item three-line class="pa-5" style="">
                      <v-list-item-content  style="color:#2C2C2C;">
                        <!-- <div class="overline mb-4">OVERLINE</div> -->
                        <v-list-item-title class="headline" color="primary" style=" font-family: 'Poppins', sans-serif!important;"> Assignments </v-list-item-title>
                         <v-list-item-avatar rounded size="60">
                        <v-icon color="primary" size="50">mdi-book-open-blank-variant</v-icon>
                      </v-list-item-avatar>
                      </v-list-item-content>
                      <v-spacer></v-spacer>
                        <h2 style="color:#2C2C2C">30</h2>

                    </v-list-item>


                  </v-card>
                </v-col>
              </v-row>
        </v-container>

</template>

<script>
export default {
    name : "OverviewCards"

}
</script>

<style>

</style>>
