<template>
    <v-overlay :value="snackbar_display" :z-index="2000">
        <div v-if="snackbar_color === 'success'">

            <v-snackbar v-model="snackbar_display" :color="snackbar_color" timeout="2000" centered>
                <!-- <v-layout align-center pr-4> -->
                <v-icon class="pr-3" dark x-large>mdi-check-circle</v-icon>
                <v-layout column class="mb-4">
                    <strong>{{ snackbar_text }}</strong>
                </v-layout>
                <!-- </v-layout> -->
                <v-spacer></v-spacer>
                <v-btn @click="snackbar_display = false" depressed x-small>CLOSE
                </v-btn>
            </v-snackbar>
        </div>

        <div v-if="snackbar_color === 'warning'">

            <v-snackbar v-model="snackbar_display" :color="snackbar_color" timeout="3000" centered>
                <!-- <v-layout align-center pr-4> -->
                <v-icon class="pr-3" dark x-large>mdi-alert</v-icon>
                <v-layout column class="mb-4">
                    <strong>{{ snackbar_text }}</strong>
                </v-layout>
                <!-- </v-layout> -->
                <v-spacer></v-spacer>
                <v-btn @click="snackbar_display = false" depressed x-small>CLOSE
                </v-btn>
            </v-snackbar>
        </div>

        <div v-else-if="snackbar_color === 'error'">

            <v-snackbar v-model="snackbar_display" :color="snackbar_color" timeout="5000" centered>
                <!-- <v-layout align-center pr-4> -->
                <v-icon class="pr-3" dark x-large>mdi-alert-circle</v-icon>
                <v-layout column class="mb-4">
                    <strong>{{ snackbar_text }}</strong>
                </v-layout>
                <!-- </v-layout> -->
                <v-spacer></v-spacer>
                <v-btn @click="snackbar_display = false" depressed x-small>CLOSE
                </v-btn>
            </v-snackbar>
        </div>
    </v-overlay>
</template>

<script>
export default {

    props: ['toast_prop'],
    data() {
        return {
            snackbar_display: false,

        }
    },
    computed: {
        snackbar_text() {
            return this.toast_prop.text;
        },
        snackbar_color() {
            return this.toast_prop.color;
        }
    },
    watch: {
        'toast_prop': {
            deep: true,
            handler() {
                this.snackbar_display = true;

            },
        },
    },

}



</script>


