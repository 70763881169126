  <template>
    <v-breadcrumbs :items="nav_items">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :disabled="item.disabled" :href="item.href">
          <span :style="{ fontSize: '14px' }">
            {{ item.text }}
          </span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </template>
<script>
export default {
  data: () => ({
  }),
  props: {
    nav_items:{type:Array}
    },
}
</script>