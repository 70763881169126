<template>

    <router-link :to="link" v-if="link">
        <div class="st-card">
            <div class="leftcard">
                <span class="name">{{title}}</span>
                <span class="numbers">{{total}}</span>
            </div>
            <div class="icon">
                <svg-vue :icon="ic"></svg-vue>
            </div>
        </div>
    </router-link>
        <div class="st-card" v-else>
            <div class="leftcard">
                <span class="name">{{title}}</span>
                <span class="numbers">{{total}}</span>
            </div>
            <div class="icon">
                <svg-vue :icon="ic"></svg-vue>
            </div>
        </div>
</template>

<script>
export default {
    props: ['title','ic','link','total'],
    methods: {
        
    },
    mounted() {
        let secondary_color=  this.$vuetify.theme.themes.light.secondary;        
       // change background color of elements of class st-card to red on mouse over
        let st_cards  = document.getElementsByClassName("st-card");

        let st_card_icons  = document.getElementsByClassName("icon");

        for (let i = 0; i < st_cards.length; i++) {
            st_card_icons[i].style.backgroundColor = secondary_color;
                
            st_cards[i].addEventListener("mouseover", function() {
                this.style.backgroundColor = secondary_color;
                st_card_icons[i].style.backgroundColor = '#ffffff';
            });
            st_cards[i].addEventListener("mouseout", function() {
                this.style.backgroundColor = '#ffffff';
                st_card_icons[i].style.backgroundColor = secondary_color;
            });
        }
    }
}
</script>
