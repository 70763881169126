import Api from './Api';
import Csrf from "./Csrf";


export default {
    async createTimeTableLecture(newTimeTableLectureForm) {
        await Csrf.getCookie();
        return Api.post('/timeTableLecture/create', newTimeTableLectureForm);
    },

    async getAllTimeTableLectures() {
        await Csrf.getCookie();
        return Api.get('/timeTableLectures');
    },

    async getMyLectures(timeTableID) {
        await Csrf.getCookie();
        return Api.get('/timeTableLectures/timeTable/' + timeTableID);
    },

    async updateTimeTableLecture(id, updateData) {
        await Csrf.getCookie();
        return Api.patch('/timeTableLecture/update/' + id, updateData);
    },
    async deleteTimeTableLecture(id) {
        await Csrf.getCookie();
        return Api.patch('/timeTableLecture/destroy/' + id);
    },

    async getTimeTableLectureCount() {
        await Csrf.getCookie();
        return Api.get('/timeTableLectures/count');
    },
};