import { defineStore } from 'pinia';


export const useInstituteStore = defineStore({
    id: 'institute',
    state: () => ({
        institute: {},
    }),
    actions: {
        setInstitute(institute) {
            this.institute = institute;
        },

        instituteId() {
            return this.institute.id;
        }
    },

    getters: {
        getInstitute(state) {
            return state.institute;
        },

        getName(state) {
            return state.institute.name;
        },

        getAccronym(state) {
            return state.institute.acronym;
        },
    },
});
