<template>
  <v-app id="inspire">
    <v-main>
            <Navigation
              :authenticatedUser="auth_user"

            >  <!---:roles="role_names" -->
                <template v-slot:items>
                  <SiderBarItems
                    :items="side_nav_items"
                    :roles="role_names"
                    :permissions="permission_names"
                    usertype="hostel-manager"
                  />
                </template>
            </Navigation>
        <template>
            <div class="container mx-6 my-4 mx-auto">
                 <Overview  v-if="$route.fullPath === '/hostel-manager'" v-bind:authenticatedUser="auth_user"></Overview>
            </div>
        <router-view  :key="$route.fullPath"/>
        </template>
      </v-main>
  </v-app>
</template>

<script>
import Navigation from '../../Navigation_modules/CustomNavigationDrawer'
import SiderBarItems from '../../Navigation_modules/SideBarItems'
import Overview from './Overview.vue'
import User from "../../../apis/User";

export default {
    name:"DepartmentDashboard",
    components: { Navigation, Overview, SiderBarItems },

    data() {
        return {
          auth_user:null,
          permission_names:[],
          role_names:[],
          side_nav_items: [],

          side_nav_items: [
            { text: 'Dashboard', icon: 'dashboard', route: 'overview', permissions:[] },
            { text: 'Clearance List', icon: 'course-reg', route: 'clearance-lists', permissions:[]},

          ]
        }
    },
     mounted() {
      User.auth().then(response=>{
        this.auth_user = response.data.data;
      });
    }
}
</script>

<style>

</style>
