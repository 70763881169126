import Api from './Api';
import Csrf from "./Csrf";


export default {

    async getDashboardCountsByHostID(id) {
        await Csrf.getCookie();
        return Api.get('/cordinator/dashboard/counts/' + id);
    },

    async getDepartmentalStudentCourseRegistrations(data) {
        await Csrf.getCookie();
        return Api.post('/cordinator/student-course-registrations', data);
    },

    async downloadCourseRegistrationReport(department_id, level_id, programme_id) {
        return Api.get('/cordinator/student-course-registrations/downloadReport/' + department_id + '/' + level_id + '/' + programme_id, {responseType: 'blob'});
    },

    async getStudentCourseRegistrations(student_id, session_id) {
        await Csrf.getCookie();
        return Api.get('/cordinator/student-course-reg/' + student_id + '/' + session_id);
    },

    async approveCourseRegistration(id) {
        await Csrf.getCookie();
        return Api.patch('/cordinator/approve-course-registration/' + id);
    },

    async rejectCourseRegistration(id, remark) {
        await Csrf.getCookie();
        return Api.patch('/cordinator/reject-course-registration/' + id + "/" + remark);
    },

    async approveAllCourses(id) {
        await Csrf.getCookie();
        return Api.patch('/cordinator/approve-all-courses/' + id );
    },

    async getProgrameStudentsCountByLevel(data) {
        await Csrf.getCookie();
        return Api.post('/cordinators/students-count', data);
    },

    async getProgrameStudentsByLevel(data) {
        await Csrf.getCookie();
        return Api.post('/cordinators/students', data);
    },
};
