
import { defineStore } from 'pinia';

export const useLevelAdvisingStore = defineStore('levelAdvising', {
  state: () => ({
    department_id: null,
    level_id: null,
    department_name: null,
    level_name: null,
    programme_id: null,
    programme_name: null,
  }),
  actions: {
   setValues(department_id = null, level_id = null, department_name = null, level_name = null, programme_id = null, programme_name = null) {
        this.department_id = department_id;
        this.level_id = level_id;
        this.department_name = department_name;
        this.level_name = level_name;
        this.programme_id = programme_id;
        this.programme_name = programme_name;
    },
  },
  getters: {
    getDepartmentId() {
          return this.department_id
      },
      getLevelId() {
          return this.level_id
      },
        getDepartmentName() {
            return this.department_name
        },
        getLevelName() {
            return this.level_name
        },
        getProgrammeId() {
            return this.programme_id
        },
        getProgrammeName() {
            return this.programme_name
        },
  },
});
