import Api from './Api';
import Csrf from "./Csrf";

export default {
    // 'Nigeria': {'abbr': 'NG', 'code': '+234'},

    async getAllCountries() {
        await Csrf.getCookie();
        return Api.get('/countries');
    },

    async getCountriesList() {
        await Csrf.getCookie();
        return Api.get('/countries-list');
    },
}
